import * as React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import FooterMenu from '../components/navigation/menu-footer'
import Seo from '../components/seo'
import Layout from '../components/layout'

import MainCTA from '../components/sections/main-cta'

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="404: Not found" />
      <header className="py-3 py-lg-4 py-xl-5 text-center text-sm-start">
        <Container fluid="xxl">
          <Row>
            <Col sm="6" className="mb-1 mb-sm-0 d-flex">
              <StaticImage
                src="../content/images/recipes/hot-meati-crispy-cutlet-sandwich-styled.jpg"
                width={660}
                quality={95}
                formats={['AUTO', 'WEBP']}
                alt="Meati™ Hot Crispy Cutlet"
                placeholder="blurred"
                sizes="(min-width: 576px) 270px, (min-width: 768px) 360px, (min-width: 992) 480px, (min-width: 1200) 570px, (min-width: 1440) 660px, 575px"
                layout="constrained"
                imgClassName="rounded-6"
                className="rounded-6"
                aspectRatio={1}
              />
            </Col>
            <Col sm="6" className="d-flex align-items-center">
              <div className="col-content p-1 py-sm-3 py-lg-4 py-xl-5 px-md-3">
                <h1 className="mb-1 font-swish">
                  404:
                  <br />
                  Not found
                </h1>
                <h2>You're getting hot!</h2>
                <p className="lead mb-1">You found Meati™ but not the page you were looking for. Check out our shop or sign up to stay in the loop.</p>

                <Nav className="justify-content-center justify-content-sm-start mt-4">
                  <FooterMenu />
                </Nav>

              </div>
            </Col>
          </Row>
        </Container>
      </header>
      <MainCTA />
    </Layout>
  )
}

export default NotFoundPage
